<template>
    <div>
        <CSTabBar :tabbar="tabBarList" :checkedTab="tabBarType" @changeTabBar="changeTabBar"></CSTabBar>
        <div class="filter-panel">
            <input
                    type="text"
                    class="cs-input"
                    style="margin-right: 30px;width: 270px"
                    :placeholder="placeholder"
                    v-model="search"
            />
            <button class="btn btn-primary" @click="queryIdCardList()">查询</button>
        </div>

        <div class="result-panel">
            <template v-if="tabBarType === 1">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">绑定时间</th>
                            <th class="text-center">姓名</th>
                            <th class="text-center">身份证</th>
                            <th class="text-center">性别</th>
                            <th class="text-center">手机号</th>
                            <th class="text-center">所属公司</th>
                            <th class="text-center">操作</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr  v-for="(item) in idCardList" :key="item.id">
                            <td>
                                {{item.bindTime || '-'}}
                            </td>
                            <td>
                                {{item.name || '-'}}
                            </td>
                            <td>
                                {{item.idCard || '-'}}
                            </td>
                            <td>
                                {{item.gender ===1 ?'男':item.gender ===1?'女':'未知'}}
                            </td>
                            <td>
                                {{item.phone || '-'}}
                            </td>
                            <td>
                                {{item.companyName || '-'}}
                            </td>
                            <td class="text-center">
                                <div
                                        class="btn-group"
                                >
                                    <button
                                            type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul
                                            class="dropdown-menu"
                                            style="min-width: 76px; width: 100px;"
                                    >
                                        <li @click="unbindIdCard(item.id,item.idCard)">
                                            <a
                                                    style="width: 100%;"
                                            >解绑</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </CSTable>
            </template>
            <template v-if="tabBarType === 2">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">解绑时间</th>
                            <th class="text-center">姓名</th>
                            <th class="text-center">身份证</th>
                            <th class="text-center">性别</th>
                            <th class="text-center">手机号</th>
                            <th class="text-center">所属公司</th>
                            <th class="text-center">解绑人</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr  v-for="(item) in idCardList" :key="item.id">
                            <td>
                                {{item.unBindTime || '-'}}
                            </td>
                            <td>
                                {{item.name || '-'}}
                            </td>
                            <td>
                                {{(item.idCard)?item.idCard.substring(0,3)+'***********'+item.idCard.substring(item.idCard.length-4):'-'}}
                            </td>
                            <td>
                                {{item.gender ===1 ?'男':item.gender ===1?'女':'未知'}}
                            </td>
                            <td>
                                {{item.phone || '-'}}
                            </td>
                            <td>
                                {{item.companyName || '-'}}
                            </td>
                            <td>
                                {{item.unBindUser || '-'}}
                            </td>
                        </tr>
                    </template>
                </CSTable>
            </template>
            <Pagination name="Pagination" component="Pagination"></Pagination>
        </div>
    </div>
</template>

<script>
    import CSTabBar from "@/components/common/CSTabBar";
    import CSTable from "@/components/common/CSTable";
    import Pagination from "@/components/Pagination.vue";
    import {
        unbindIdCardUrls,
        queryUnbindIdCardUrls,
        queryBindIdCardUrls,
    } from "@/requestUrl";

    export default {
        name: "IdentityBinding",
        components: {
            CSTabBar,
            CSTable,
            Pagination
        },
        data() {
            return {
                tabBarList: {
                    1: "身份证绑定管理",
                    2: "身份证解绑记录",
                },
                tabBarType: 1,
                placeholder: "搜索完整姓名/完整手机号/完整身份证",
                search: "",
                filterHeight: 0,
                idCardList:[],
            }
        },
        mounted() {
            this.queryIdCardList();
            this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
                this.queryIdCardList(_currentPage);
            });
        },
        methods: {
            changeTabBar(index) {
                this.tabBarType = index;
                this.search = "";
                this.placeholder = index === 1?'搜索完整姓名/完整手机号/完整身份证':'搜索姓名/手机号';
                this.queryIdCardList();
            },
            queryIdCardList(pageNo = 1){
                if (this.tabBarType === 1){
                    this.queryBindIdCard(pageNo)
                }else {
                    this.queryUnbindIdCard(pageNo);
                }
            },
            queryBindIdCard(){
                this.$fly.get(queryBindIdCardUrls, {
                        search:this.search
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.idCardList = res.data.datas || [];
                    });
            },
            queryUnbindIdCard(pageNo = 1,pageSize = 10){
                this.$fly.post(queryUnbindIdCardUrls, {
                    search:this.search,
                    pageNo,
                    pageSize,
                    regionCode:this.$vc.getCurrentRegion().code
                })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        if(pageNo === 1 && res.data.datas){
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                currentPage: pageNo,
                                pageSize: res.data.total ? pageSize : res.data.datas.length,
                            });
                        }
                        this.idCardList = res.data.datas || [];
                    });
            },
            unbindIdCard(userId,idCard){
                this.$CSDialog.confirm({
                    title: "提示",
                    message: `确定要解绑吗？解绑后，其他用户可与该身份证`+idCard+`绑定。`,
                    onConfirm: () => {
                        this.$fly.get(unbindIdCardUrls, {
                            userId
                            })
                            .then((res) => {
                                if (res.code != 0) {
                                    return;
                                }
                                this.$vc.toast("解绑成功");
                                this.$CSDialog.instance.closeDialog();
                                this.queryBindIdCard();
                            });
                    },
                });
            },
        }
    }
</script>

<style scoped>

</style>
